const sharedConfig = require("./SharedConfig");

let config = {
  ...sharedConfig,
  siteUrl: "https://svenskmedborgare.se/", // Domain of your website without pathPrefix.
  pathPrefix: "/v1", // Prefixes all links. For cases when deployed to example.github.io/gatsby-advanced-starter/.
  orderUrl: "/order.php",
  stripePk: 'pk_live_51RBYSIIcqqec86cYDPAf8TJQuqKrDVEsIG9RBoQhEe6YIs0x9q1sEt5SoFul5WnWQWLWnfGB1qqXWC6C6K5ZZRb200ohcjnHus',
  stripeUrl: "https://8f9oauwho8.execute-api.us-east-1.amazonaws.com/prod/svenskmedborgare-payments", // "https://suxtdz55di.execute-api.us-east-1.amazonaws.com/dev/svenskmedborgare-payments",
};

config.validate();

module.exports = config;
